import { LitElement, css, html, nothing } from 'lit';
import { property, state } from 'lit/decorators.js';
import '@mch/nn-web-viz/dist/nn-spinner';
import { connect, store } from '../../../../state/store';
import { setSelectedThread } from '../../../../state/slices/thread';
import { navigate } from '@mch/nn-web-viz/dist/packages/router';
import { routePrefix } from '../../../../utils';

interface Thread {
  title: string;
  description: string;
}

class AdeleGlowCardsCollection extends connect(store)(LitElement) {
  @property({ type: Array }) cards: {
    id: number;
    title: string;
    description: string;
    imgSrc?: string;
    threads?: Array<any>;
  }[] = [];

  @property({ type: Number }) proximity = 100;

  @property({ type: Number }) gap = 32;

  @property({ type: Number }) blurValue = 20;

  @property({ type: Number }) spread = 60;

  @property({ type: Boolean }) vertical = false;

  @property({ type: Number }) opacity = 0;

  @property({ type: String }) direction = 'row';

  @property({ type: Number }) expandedCardId: number | null = null;

  @property({ type: Number }) idToShowThreads: number | null = null;

  @property({ type: Array }) threads: Thread[][] = [];

  @property({ type: Array }) _threadsToShow: Array<any> = [];

  private animationFrameId: number | null = null;

  static styles = css`
    :host {
      --bg: hsl(246 44% 7%);
      --border: hsl(280 10% 50% / 1);
      --card: hsl(237 36% 10%);
      --color: hsl(240 18% 80%);
      --border-width: 2px;
      --border-radius: 16px;
      --gradient: conic-gradient(
        from 180deg at 50% 70%,
        hsla(0, 0%, 98%, 1) 0deg,
        #f8029c 90deg,
        #6c00ff 180deg,
        #0dbeff 270deg,
        #00dfb1 360deg,
        hsla(0, 0%, 98%, 1) 1turn
      );
      --blurValue: 20;
      --start: 0;
    }

    *,
    *:after,
    *:before {
      box-sizing: border-box;
    }

    .glow-body {
      display: grid;
      place-items: center;
      font-family: 'Geist Sans', 'SF Pro Text', 'SF Pro Icons', 'AOS Icons',
        'Helvetica Neue', Helvetica, Arial, sans-serif, system-ui;
      font-weight: 70;
      color: hsl(240 18% 80%);
      width: 100%;
      height: 100%;
    }

    .glow-container {
      --spread: 60;
      display: flex;
      flex-wrap: wrap;
      flex-direction: var(--direction);
      gap: var(--gap);
      margin: 0 auto;
      justify-content: center;
      place-items: center;
      position: relative;
      padding: 2rem;
      touch-action: none;
      transition: all 0.3s ease;
    }

    article {
      --active: 0.15;
      --start: 0;
      height: 100%;
      width: 280px;
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      position: relative;
      padding: 16px 19px;
      border-radius: 16px;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 24px 30px 0px;
      backdrop-filter: blur(18px);
      transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
      cursor: pointer;
    }

    article:is(:hover, :focus-visible) {
      z-index: 2;
    }

    .glows {
      pointer-events: none;
      position: absolute;
      inset: 0;
      filter: blur(20px);
    }

    .glows::after,
    .glows::before {
      --alpha: 0;
      --spread: 60;
      content: '';
      background: var(--gradient);
      background-attachment: fixed;
      position: absolute;
      inset: -5px;
      border: 10px solid transparent;
      border-radius: 16px;
      mask: linear-gradient(#0000, #0000),
        conic-gradient(
          from calc((var(--start) - (var(--spread) * 0.5)) * 1deg),
          #000 0deg,
          #fff,
          transparent calc(var(--spread) * 1deg)
        );
      mask-composite: intersect;
      mask-clip: padding-box, border-box;
      opacity: var(--active);
      transition: opacity 1s;
    }

    article::before {
      position: absolute;
      inset: 0;
      border: 2px solid transparent;
      content: '';
      pointer-events: none;
      background: hsl(280 10% 50% / 1);
      background-attachment: fixed;
      border-radius: 16px;
      mask: linear-gradient(#0000, #0000),
        conic-gradient(
          from
            calc(
              ((var(--start) + (var(--spread) * 0.25)) - (var(--spread) * 1.5)) *
                1deg
            ),
          hsl(0 0% 100% / 0.15) 0deg,
          white,
          hsl(0 0% 100% / 0.15) calc(var(--spread) * 2.5deg)
        );
      mask-clip: padding-box, border-box;
      mask-composite: intersect;
      opacity: var(--active);
      transition: opacity 1s;
    }

    article::after {
      --bg-size: 100%;
      content: '';
      pointer-events: none;
      position: absolute;
      background: var(--gradient);
      background-attachment: fixed;
      border-radius: 16px;
      opacity: var(--active, 0);
      transition: opacity 1s;
      --alpha: 0;
      inset: 0;
      border: 2px solid transparent;
      mask: linear-gradient(#0000, #0000),
        conic-gradient(
          from
            calc(
              ((var(--start) + (var(--spread) * 0.25)) - (var(--spread) * 0.5)) *
                1deg
            ),
          #0000 0deg,
          #fff,
          #0000 calc(var(--spread) * 0.5deg)
        );
      filter: brightness(1.5);
      mask-clip: padding-box, border-box;
      mask-composite: intersect;
    }

    .badge {
      border: 2px solid hsl(280 10% 50% / 1);
      align-self: start;
      border-radius: 100px;
      padding: 0.5rem 0.7rem;
      font-size: 0.675rem;
      display: flex;
      align-items: center;
      gap: 0.25rem;
      font-weight: 50;
      width: 130px;
    }

    a {
      color: hsl(240 18% 80%);
      text-decoration: none;
      opacity: 0.5;
      display: inline-block;
      align-self: start;
      transition: opacity 0.2s;
    }

    a:is(:hover, :focus-visible) {
      opacity: 1;
    }

    article h2 {
      margin: 0;
      padding: 1rem 0;
      font-weight: 100;
      font-size: 1.5rem;
    }

    article h2,
    article p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .header {
      position: relative;
      flex: 1;
      display: flex;
      align-items: center;
    }

    .header svg {
      --count: 4;
      width: 106px;
    }

    .header svg:nth-of-type(2),
    .header svg:nth-of-type(3),
    .header svg:nth-of-type(4) {
      position: absolute;
      z-index: calc(var(--count) - var(--index));
      translate: calc(var(--index) * 30%) 0;
      opacity: calc(var(--count) / (2 * (var(--index) * 10)));
    }

    .header svg:nth-of-type(2) {
      --index: 1;
    }
    .header svg:nth-of-type(3) {
      --index: 2;
    }
    .header svg:nth-of-type(4) {
      --index: 3;
    }

    .badge svg {
      width: 16px;
    }

    .dg.ac {
      z-index: 99999 !important;
    }

    .details {
      display: none;
      flex-direction: column;
      overflow: hidden;
      transition: max-height 0.3s ease, display 0.3s ease;
      gap: 0.5rem;
      margin: 0 0 0 10px;
      max-height: 285px;
      overflow: auto;
      width: 301px;
    }

    ::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-thumb {
      border: 4px solid rgba(255, 255, 255, 0.3);
      background-clip: padding-box;
      border-radius: 25px;
      height: 50px;
    }

    .threads-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    .thread-item {
      cursor: pointer;
      display: flex;
      font-weight: 700;
      margin: 0 8px 0 0;
      border-radius: 12px;
      padding: 0.5rem 0.75rem;
      transition: background 0.3s, color 0.3s;
      text-transform: capitalize;
    }

    .thread-item:hover {
      background: rgba(255, 255, 255, 0.1);
      color: #fff;
    }

    .card-main-left {
      width: 242px;
      height: 285px;
    }
  `;

  constructor() {
    super();
    this.cards = [];
    this.threads = [];
    this.restyle();
  }

  updated(changedProps) {
    super.updated(changedProps);

    if (changedProps.has('idToShowThreads')) {
      if (this.idToShowThreads != null) {
        this._threadsToShow =
          this.cards.find(card => card.id === this.idToShowThreads)?.threads ||
          [];
      }
    }
  }

  toggleCardExpansion(cardId) {
    this.idToShowThreads = this.idToShowThreads === cardId ? null : cardId;
    this.cards.forEach(card => {
      const cardElement = this.shadowRoot?.querySelector(
        `article[data-id="${card.id}"]`
      ) as HTMLElement;
      if (cardElement) {
        cardElement.style.width =
          this.idToShowThreads === card.id ? '593px' : '280px';
        cardElement.style.flexDirection =
          this.idToShowThreads === card.id ? 'row' : 'column';
        const details = cardElement.querySelector('.details') as HTMLElement;
        details.style.display =
          this.idToShowThreads === card.id ? 'inline-flex' : 'none';

        const description = cardElement.querySelector(
          '#description'
        ) as HTMLElement;
        const image = cardElement.querySelector('img') as HTMLElement;
        if (description && image && card.description.length > 33) {
          if (this.idToShowThreads === card.id) {
            description.style.height = '99px';
            description.style.overflowY = 'scroll';
            image.style.height = '80px';
          } else {
            description.style.height = '20px';
            description.style.overflowY = 'scroll';
            image.style.height = '160px';
            image.style.width = '100%';
            image.style.objectFit = 'cover';
          }
        }
      }
      const badge = this.shadowRoot?.querySelector(
        `article[data-id="${card.id}"] .view-collection`
      );
      if (badge) {
        badge.innerHTML =
          this.idToShowThreads === card.id
            ? 'Hide Collection'
            : 'View Collection';
      }
    });
  }

  restyle() {
    const container = this.shadowRoot?.querySelector(
      '.glow-container'
    ) as HTMLElement;
    if (container) {
      container.style.setProperty('--gap', `${this.gap}px`);
      container.style.setProperty('--blur-value', `${this.blurValue}px`);
      container.style.setProperty('--spread', `${this.spread}`);
      container.style.setProperty(
        '--direction',
        this.vertical ? 'column' : 'row'
      );
    }
  }

  updateCardStyles(event) {
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
    }
    this.animationFrameId = requestAnimationFrame(() => {
      this.cards.forEach(card => {
        const cardElement = this.shadowRoot?.querySelector(
          `article[data-id="${card.id}"]`
        );
        if (cardElement instanceof HTMLElement) {
          const bounds = cardElement.getBoundingClientRect();
          const inProximity =
            event.clientX > bounds.left - this.proximity &&
            event.clientX < bounds.right + this.proximity &&
            event.clientY > bounds.top - this.proximity &&
            event.clientY < bounds.bottom + this.proximity;

          const currentActive =
            cardElement.style.getPropertyValue('--active') === '1';
          if (inProximity && !currentActive) {
            cardElement.style.setProperty('--active', '1');
          } else if (!inProximity && currentActive) {
            cardElement.style.setProperty('--active', '0');
          }

          if (inProximity) {
            const centerX = bounds.left + bounds.width / 2;
            const centerY = bounds.top + bounds.height / 2;
            let angle =
              Math.atan2(event.clientY - centerY, event.clientX - centerX) *
              (180 / Math.PI);
            angle = angle < 0 ? angle + 360 : angle;
            angle = Math.round(angle);
            cardElement.style.setProperty('--start', `${angle + 90}`);
          }
        }
      });
    });
  }

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener('pointermove', this.updateCardStyles.bind(this));
    this.restyle();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeEventListener('pointermove', this.updateCardStyles.bind(this));
  }

  cardClicked(cardId) {
    this.dispatchEvent(
      new CustomEvent('card-selected', {
        detail: { cardId },
        bubbles: true,
        composed: true,
      })
    );
  }

  _handleThreadClick(threadId) {
    const event = new CustomEvent('thread-clicked', {
      detail: { threadId },
      bubbles: true,
      composed: true,
    });
    this.dispatchEvent(event);
  }

  _goToThread(id) {
    navigate(`${routePrefix}/threads`);
    store.dispatch(setSelectedThread(id));
    window.history.replaceState({}, '', `/app/threads?threadId=${id}`);
  }

  _renderThreadsToShow(id) {
    return this.idToShowThreads === id
      ? this._threadsToShow.map(thread => {
          return html`<div
            class="thread-item"
            @click=${() => this._goToThread(thread.id)}
          >
            <div>${thread.name}</div>
          </div>`;
        })
      : nothing;
  }

  render() {
    return html`
      <div class="glow-body">
        <div
          class="glow-container"
          style="--gap: ${this.gap}px; --blur-value: ${this
            .blurValue}px; --spread: ${this.spread}; --direction: ${this
            .vertical
            ? 'column'
            : 'row'};"
        >
          ${this.cards.map(
            (card, index) => html`
              <article
                data-id="${card.id}"
                @click=${() => this.toggleCardExpansion(card.id)}
                style="--active: ${this.opacity};"
              >
                <div class="glows"></div>
                <div class="card-main-left">
                  <span class="header">
                    <img
                      style="height:160px;width:100%;object-fit: cover;"
                      src="${card.imgSrc || ''}"
                      alt=""
                    />
                  </span>
                  <h2>${card.title}</h2>
                  <p id="description" style="height:20px;margin:0 0 8px 0;">
                    ${card.description}
                  </p>
                  <span class="badge">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M9 4.5a.75.75 0 01.721.544l.813 2.846a3.75 3.75 0 002.576 2.576l2.846.813a.75.75 0 010 1.442l-2.846.813a3.75 3.75 0 00-2.576 2.576l-.813 2.846a.75.75 0 01-1.442 0l-.813-2.846a3.75 3.75 0 00-2.576-2.576l-2.846-.813a.75.75 0 010-1.442l2.846-.813A3.75 3.75 0 007.466 7.89l.813-2.846A.75.75 0 019 4.5zM18 1.5a.75.75 0 01.728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 010 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 01-1.456 0l-.258-1.036a2.625 2.625 0 00-1.91-1.91l-1.036-.258a.75.75 0 010-1.456l1.036-.258a2.625 2.625 0 001.91-1.91l.258-1.036A.75.75 0 0118 1.5zM16.5 15a.75.75 0 01.712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 010 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 01-1.422 0l-.395-1.183a1.5 1.5 0 00-.948-.948l-1.183-.395a.75.75 0 010-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0116.5 15z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <span class="view-collection">View Collection</span>
                  </span>
                </div>
                <div class="details">${this._renderThreadsToShow(card.id)}</div>
              </article>
            `
          )}
        </div>
      </div>
    `;
  }
}

export { AdeleGlowCardsCollection };
