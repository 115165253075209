import { property } from 'lit/decorators';
import { PropertyValues } from 'lit';

// Web Viz Components
import {
  CSSResult,
  NNBase,
  html,
  nothing,
} from '@mch/nn-web-viz/dist/packages/base/Base';
import '@mch/nn-web-viz/dist/nn-tiles';
import '@mch/nn-web-viz/dist/nn-button';

// Components
import '../../../components/adele-collections-sidebar';
import '../../../components/adele-glow-cards-collection';

// Utils
import moment from 'moment';
import { navigate } from '@mch/nn-web-viz/dist/packages/router/utils';
import { trackPageView } from '@mch/nn-web-viz/dist/packages/analytics';
import { routePrefix } from '../../../utils';

// Styles
import { BaseStyles } from '../../../assets/styles/baseStyles';
import { AdeleCollectionsStyles } from './AdeleCollectionsStyles';

// Store
import { connect, store } from '../../../state/store';

// Hasura
import { hasura, hasuraQueries } from '../../../modules/hasura';

// Define the _navigateToThread method to handle the thread click event
const navigateToThread = event => {
  const { threadId } = event.detail;
  navigate(`${routePrefix}/threads`);
  window.history.replaceState({}, '', `/app/threads?threadId=${threadId}`);
};

const renderContinueReadingButton = id => html`<nn-button
  outlined
  inner-color="#111322"
  color="#FFFFFF"
  style="width: 186px; margin-left: 40px;"
  @click=${() => navigateToThread(id)}
  >CONTINUE READING</nn-button
>`;

class AdeleCollections extends connect(store)(NNBase) {
  @property({ type: Object }) openCollectionsSidebar;

  @property({ type: Object }) _allThreads: any = [];

  @property({ type: Array }) _getThreadsToDisplay: Array<any> = [];

  @property({ type: Array }) _collections: Array<any> = [];

  @property({ type: Boolean }) _loadingCollections: boolean = false;

  @property({ type: String }) _selectedCollectionId: string = '';

  @property({ type: Array }) _selectedCollection: Array<any> = [];

  @property({ type: Boolean }) _loadingSelectedCollection: boolean = false;

  static styles: CSSResult[] = [BaseStyles, AdeleCollectionsStyles];

  constructor() {
    super();

    this.openCollectionsSidebar = false;
    this._getCollections();
    this.handleCardSelection = this.handleCardSelection.bind(this);
  }

  updated(changedProps) {
    super.updated(changedProps);

    if (changedProps.has('_selectedCollectionId')) {
      if (
        this._selectedCollectionId == null ||
        this._selectedCollectionId === '' ||
        this._selectedCollectionId === 'all'
      ) {
        this._getAllThread();
      } else {
        this._getSelectedCollectionById();
      }
    }
  }

  protected firstUpdated(_changedProperties: PropertyValues): void {
    super.firstUpdated(_changedProperties);

    trackPageView({ page: 'COLLECTIONS_PAGE' });
  }

  handleCardSelection(event) {
    const { cardId } = event.detail;
    this._selectedCollectionId = cardId;
    this.requestUpdate(); // Trigger LitElement's update lifecycle if needed
  }

  async _getSelectedCollectionById() {
    this._loadingSelectedCollection = true;

    const result = await hasura.query(
      hasuraQueries.getCollectionById({ id: this._selectedCollectionId })
    );
    this._selectedCollection = result.collection;

    this._loadingSelectedCollection = false;
  }

  async _getAllThread() {
    const result = await hasura.query(hasuraQueries.getThreads());

    this._allThreads = result?.thread.sort((a, b) => a.id - b.id);
  }

  async _getCollections() {
    this._loadingCollections = true;

    const collectionsResult = await hasura.query(
      hasuraQueries.getCollections()
    );
    this._collections = collectionsResult.collection;

    const threadsResult = await hasura.query(hasuraQueries.getThreads());
    this._allThreads = threadsResult.thread;

    this._loadingCollections = false;
  }

  _toggleCollectionsSidebar() {
    this.openCollectionsSidebar = !this.openCollectionsSidebar;
  }

  _closeCollectionsSidebar() {
    this.openCollectionsSidebar = false;
    this._getCollections();
  }

  _setSelectedCollection(value) {
    this._selectedCollectionId = value;
  }

  render() {
    const cardImageUrls = [
      'https://assets.codepen.io/7773162/purple-bg.jpeg?format=auto&quality=20',
      'https://assets.codepen.io/7773162/orange-bg+2+Small.png?format=auto&quality=80',
      'https://assets.codepen.io/7773162/yellow-bg.jpeg?format=auto&quality=50',
      'https://assets.codepen.io/7773162/red-bg.jpeg?format=auto&quality=50',
      'https://assets.codepen.io/7773162/glass-bg.jpeg?format=auto&quality=20',
    ];

    const cardsData = this._collections.map((collection, index) => ({
      id: collection.id,
      title: collection.name,
      description: collection.description,
      imgSrc: cardImageUrls[index % cardImageUrls.length],
      // selected: collection.id === this._selectedCollectionId,
      threads: collection.thread_collections.map(t => ({
        ...t?.thread,
        name: t?.thread?.name || 'Unnamed thread',
      })),
    }));

    cardsData.unshift({
      id: 'all',
      title: 'All Threads',
      description: 'All Threads Collection',
      imgSrc: cardImageUrls[4],
      threads: this._allThreads.map(t => ({
        ...t,
        name: t?.messages?.[0]?.question || 'Unnamed thread',
      })),
    });

    return html`
      <div class="page-wrapper">
        <div class="container">
          <div class="left-column">
            <div class="left-header">
              <h1>Collections</h1>

              <div
                class="glass-button"
                @click=${this._toggleCollectionsSidebar}
                @keyup=${this._toggleCollectionsSidebar}
              >
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                New
              </div>
            </div>
            ${this._loadingCollections
              ? html`<nn-spinner theme="default"></nn-spinner>`
              : html`<adele-glow-cards-collection
                  .cards=${cardsData}
                  @card-selected=${this.handleCardSelection}
                  @thread-clicked=${navigateToThread}
                ></adele-glow-cards-collection>`}
          </div>
        </div>
      </div>
      ${this.openCollectionsSidebar
        ? html`<adele-collections-sidebar
            .createModeOnly=${true}
            .createCollectionMode=${true}
            @close-collections-sidebar=${this._closeCollectionsSidebar}
          ></adele-collections-sidebar>`
        : nothing}
    `;
  }

  _renderThreads() {
    if (
      this._selectedCollectionId == null ||
      this._selectedCollectionId === '' ||
      this._selectedCollectionId === 'all'
    ) {
      return this._allThreads.map(
        thread => html`
          <div class="thread">
            <div class="thread__left">
              <h3>${thread?.messages?.[0]?.question}</h3>
              <p>${thread?.messages?.[0]?.answer}</p>
              <div class="threads-footer">
                <time>${moment(thread.updated, 'YYYYMMDD').fromNow()}</time>
              </div>
            </div>
            <div class="thread__right">
              ${renderContinueReadingButton(thread.id)}
            </div>
          </div>
        `
      );
    }

    if (!this._selectedCollection.length) return nothing;

    if (!this._selectedCollection[0].thread_collections.length)
      return html`<h3>Empty collection</h3>`;

    return this._selectedCollection[0].thread_collections.map(
      ({ thread }) => html`
        <div class="thread">
          <div class="thread__left">
            <h3>${thread.name}</h3>
            <p>${thread?.history[0]?.answer}</p>
            <div class="threads-footer">
              <time>1d ago</time>
            </div>
          </div>
          <div class="thread__right">
            ${renderContinueReadingButton(thread.id)}
          </div>
        </div>
      `
    );
  }
}

export { AdeleCollections };
