import { css } from 'lit';

const AdeleCollectionsStyles = css`
  .page-wrapper {
    border-radius: 24px;

    background: linear-gradient(
      237deg,
      rgba(28, 31, 40, 0.1) 5.65%,
      rgba(28, 31, 40, 0.3) 85.87%,
      rgba(28, 31, 40, 0.5) 97.63%
    );
    box-shadow: 0px 24px 30px 0px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(18px);
    color: white;
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .container {
    margin: 8px 30px;
    display: flex;
    gap: 130px;
  }

  .left-column {
    display: flex;
    flex-direction: column;
    min-width: 100%;
    max-width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow-y: auto;
  }

  .right-column {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    box-sizing: border-box;
  }

  .left-header {
    display: flex;
    align-items: center;
  }

  .left-header h1 {
    margin-right: 24px;
  }

  .collections-container {
    display: flex;
    flex-direction: column;
    gap: 19px;
    height: 100%;
    overflow-y: auto;
  }

  .threads {
    height: 100%;
    overflow-y: auto;
  }

  .collection-item {
    cursor: pointer;
    max-width: 435px;
    border: 1px solid #a0a6d0;
    border-radius: 8px;
    background: transparent;
    padding: 14px 28px;
  }

  .collection-item.selected {
    background: #242848;
  }

  .collection-name {
    font-family: Nunito Sans;
    font-size: 22px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
  }

  .collection-description {
    font-family: Nunito Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
  }

  .collection-threads {
    font-family: Nunito Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
  }

  .thread {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #cecece;
    padding-bottom: 22px;
  }

  .thread:last-child {
    border-bottom: none;
  }

  .thread__left {
    flex: 1;
  }

  time {
    font-size: 12px;
    display: block;
    color: #90e1ff;
  }

  .glass-button {
    position: relative;
    padding: 15px 30px;
    text-decoration: none;
    color: #fff;
    font-optical-sizing: 2em;
    text-transform: uppercase;
    font-family: sans-serif;
    letter-spacing: 4px;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    border-radius: 10px;
  }
  .glass-button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: rgba(255, 255, 255, 0.1);
  }
  .glass-button::after {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.4),
      transparent
    );
    transition: 0.5s;
    transition-delay: 0.5s;
  }
  .glass-button:hover::after {
    left: 100%;
  }
  .glass-button span {
    position: absolute;
    display: block;
    transition: 0.5s ease;
    border-radius: 10px;
  }
  .glass-button span:nth-child(1) {
    top: 0;
    left: 0;
    width: 0;
    height: 1px;
    background: #fff;
  }
  .glass-button:hover span:nth-child(1) {
    width: 100%;
    transform: translateX(100%);
  }
  .glass-button span:nth-child(3) {
    bottom: 0;
    right: 0;
    width: 0;
    height: 1px;
    background: #fff;
  }
  .glass-button:hover span:nth-child(3) {
    width: 100%;
    transform: translateX(-100%);
  }
  .glass-button span:nth-child(2) {
    top: 0;
    left: 0;
    width: 1px;
    height: 0;
    background: #fff;
  }
  .glass-button:hover span:nth-child(2) {
    height: 100%;
    transform: translateY(100%);
  }
  .glass-button span:nth-child(4) {
    bottom: 0;
    right: 0;
    width: 1px;
    height: 0;
    background: #fff;
  }
  .glass-button:hover span:nth-child(4) {
    height: 100%;
    transform: translateY(-100%);
  }
`;

export { AdeleCollectionsStyles };
